import React, {useEffect, useReducer} from "react";
import {useDispatch, useSelector} from "react-redux";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {p} from "@pg-design/helpers-css";
import {Loader} from "@pg-design/loader";
import {useABTest} from "@pg-mono/ab-testing";

import {IRPStore} from "../../app/rp_reducer";
import {IMarketplaceArchitectOfferSummary} from "../../architect_application/api/fetch_marketplace_architect_promoted_offers_by_region";
import {ArchitectMultileadModalContent} from "../../architect_application/components/ArchitectMultileadModalContent";
import {ArchitectOfferSummaryModalContent} from "../../architect_application/components/ArchitectOfferSummaryModalContent";
import {getPhoneDataFromString} from "../../form/utils/get_phone_data_from_string";
import {MultiLeadSentSummary} from "../../lead/components/MultiLeadSentSummary";
import {MultileadSentSummarySimplifiedWithComparison} from "../../lead/components/MultileadSentSummarySimplifiedWithComparison";
import {MultileadSentSummaryWithCollapsedOffers} from "../../lead/components/MultileadSentSummaryWithCollapsedOffers";
import {useInitialLeadFormValues} from "../../lead/hooks/use_initial_lead_form_values";
import {useLeadData} from "../../lead/hooks/use_lead_data";
import {hitMultileadRecommendationShown} from "../../lead/tracking/hit_multilead_recommendation_shown";
import {IRealEstateIds} from "../../lead/types/IRealEstateIds";
import {MultileadVersion} from "../../lead/types/MultileadVersion";
import {RecommendationSource} from "../../lead/types/RecommendationSource";
import {isLeadWithPriceConfirmation} from "../../lead/utils/is_lead_with_price_confirmation";
import {getSentLeadFormValuesFromStorage} from "../../lead/utils/sent_lead_form_values";
import {IRegionAncestor} from "../../offer/list/actions/offer_list_selected_data_actions";
import {IRecommendedOffer} from "../../recommendations/types/IRecommendedOffer";
import {IRecommendedProperty} from "../../recommendations/types/IRecommendedProperty";
import {Country} from "../../region/types/Country";
import {fadeInAnimation} from "../../search/components/atoms/atoms";
import {gtmStepApplicationClose} from "../../tracking/google_tag_manager/gtm_event_step_application_close";
import {multiLeadApplicationsActionsTypes} from "../actions/multi_lead_applications_actions";
import {initialEmptyApplicationValues} from "../constants/application_form";
import {multiLeadSummaryAbTestInstance} from "../constants/multi_lead_summary_ab_test_instance";
import {useApplicationVariant} from "../hooks/use_application_variant";
import {initialStepState, ModalStepActionTypes, modalStepReducer} from "../reducers/modal_step_reducer";
import {AdditionalOfferCategoryGTMEventAction, multiLeadAdditionalOfferAnalytics} from "../tracking/multilead_additional_offers_tracking";
import {ApplicationVariant} from "../types/ApplicationVariant";
import {IApplication} from "../types/IApplication";
import {IApplicationOffer} from "../types/IApplicationOffer";
import {IApplicationProperty} from "../types/IApplicationProperty";
import {IMultiLeadStepPayload} from "../types/IMultiLeadStepPayload";
import {IMultileadSummaryStepState} from "../types/IMultileadSummaryStepState";
import {INotificationConsentStepPayload} from "../types/INotificationConsentStepPayload";
import {IOriginalApplicationProperty} from "../types/IOriginalApplicationProperty";
import {ISetMultiLeadSummaryStepPayload} from "../types/ISetMultiLeadSummaryStepPayload";
import {LeadModalStep} from "../types/LeadModalStep";
import {MultiLeadRecommendationType} from "../types/MultiLeadRecommendationType";
import {ApplicationSource, ApplicationSourceSection} from "../utils/ApplicationSource";
import {isAbroad} from "../utils/is_abroad";
import {fetchSimpleMultiLeadRecommendations} from "../utils/recommendations/fetch_simple_multilead_recommendations";
import {report} from "../utils/recommended_application/analytics_events";
import {MultiLead} from "./multi_lead/MultiLead";
import {AppliedPMOffer} from "./multi_lead_promo/AppliedPMOffer";
import {PMPromoModalLayout} from "./multi_lead_promo/PMPromoModalLayout";
import {ApplicationPropertyPriceUserBudgetModal} from "./property_price/ApplicationPropertyPriceUserBudgetModal";
import {ApplicationPropertyPriceUserBudgetNoRecommendationsModal} from "./property_price/ApplicationPropertyPriceUserBudgetNoRecommendationsModal";
import {SameOfferRecommendedPropertiesModal} from "./same_offer_recommended_properties/SameOfferRecommendedPropertiesModal";
import {Application, IApplicationOwnProps} from "./Application";
import {ApplicationGoodbyeContentModal} from "./ApplicationGoodbyeContentModal";
import {ApplicationStyledModal} from "./ApplicationStyledResponsiveModal";
import {AppliedArchitectInteriorDesign} from "./AppliedArchitectInteriorDesign";
import {AppliedProperties} from "./AppliedProperties";
import {FormSuccessInfoContainer} from "./FormSuccessInfoContainer";
import {NotificationConsentConfirmation} from "./NotificationConsentConfirmation";

type IProps = Omit<IApplicationOwnProps, "leadData" | "initialLeadFormValues" | "appendPreMultileadApplications" | "handleChangeUserBudget"> & {
    closeModal: () => void;
    isMobile: boolean;
    isOpened: boolean;
    modalArrayClassNames?: string[];
    initialStep?: LeadModalStep;
    preventClosingWhenNoData?: boolean;
    customSourceSection?: string;
    applicationVariant?: ApplicationVariant;
    creditPayload?: {price: number; contribution: number; period: number};
    customFormText?: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ApplicationModalArray = ({closeModal, isOpened, initialStep, preventClosingWhenNoData, customFormText, ...restProps}: IProps) => {
    const {abTestVariant: multiLeadSummaryVariant} = useABTest(multiLeadSummaryAbTestInstance);
    const dispatch = useDispatch();
    const applicationVariant = useApplicationVariant({
        applicationVariant: restProps.applicationVariant,
        offerId: restProps.offerId,
        propertyId: restProps.propertyId
    });

    const parametrizedStoreHash = getStoreHash(restProps.storeHash, {
        vendorId: restProps.vendorId,
        offerId: restProps.offerId,
        propertyId: restProps.propertyId
    });

    const roomCount = useSelector((store: IRPStore) => store.application[parametrizedStoreHash]?.property?.detail?.rooms) as number;

    const leadData = useLeadData({
        storeHash: parametrizedStoreHash,
        vendorId: restProps.vendorId,
        offerId: restProps.offerId,
        propertyId: restProps.propertyId,
        skipFetch: !isOpened
    });

    const initialLeadFormValues = useInitialLeadFormValues({
        storeHash: parametrizedStoreHash,
        leadData,
        realEstateIds: {offerId: restProps.offerId, propertyId: restProps.propertyId, vendorId: restProps.vendorId},
        source: restProps.source,
        resetValues: !isOpened,
        /*
            TODO: IApplicationOwnProps seems to have promotion and preventStorageOfferSearchLoad in its interface, but those doesn't seem to be passed anywhere
                Analyze and remove from implementation or fix missing props passing in some place
         */
        promotion: restProps.promotion,
        preventStorageOfferSearchLoad: restProps.preventStorageOfferSearchLoad,
        customFormText
    });

    /**
     * State
     */
    const isAutoresponderEnabled = useSelector(
        (state: IRPStore) => state.application[parametrizedStoreHash]?.offer?.detail?.configuration.is_autoresponder_enabled
    );
    const currentViewType = useSelector((store: IRPStore) => store.viewType.current);

    const [modalStepState, dispatchModalStepState] = useReducer(modalStepReducer, {
        ...initialStepState,
        ...(initialStep && {step: initialStep}),
        isAutoresponderEnabled: !!isAutoresponderEnabled
    });

    /**
     * Callback
     */
    useEffect(() => {
        if (isOpened && !initialStep) {
            dispatchModalStepState({type: ModalStepActionTypes.OPEN});
        }
    }, [isOpened]);

    useEffect(() => {
        if (modalStepState.step === LeadModalStep.CLOSE) {
            closeModal();
        }
    }, [modalStepState.step]);

    useEffect(() => {
        // `isAutoresponderEnabled` is value from API, so we need to update this value in reducer state
        if (isOpened && isAutoresponderEnabled) {
            dispatchModalStepState({type: ModalStepActionTypes.SET_IS_AUTORESPONDER_ENABLED, payload: {isAutoresponderEnabled}});
        }
    }, [isAutoresponderEnabled, isOpened]);

    useEffect(() => {
        if (leadData) {
            dispatchModalStepState({type: ModalStepActionTypes.UPDATE_LEAD_DATA, payload: leadData});
        }
    }, [leadData, isOpened]);

    const idProps: IRealEstateIds = {
        vendorId: restProps.vendorId,
        offerId: restProps.offerId,
        propertyId: restProps.propertyId
    };

    const recommendationType = idProps.propertyId ? MultiLeadRecommendationType.PROPERTY : MultiLeadRecommendationType.OFFER;
    const recommendationSource =
        restProps.source === ApplicationSource.ApplicationOfferScheduleMeeting ? RecommendationSource.MODEL : RecommendationSource.DISTANCE;

    // `forceClose` - close without any additional modals
    const onModalClose = (forceCloseParam?: boolean) => {
        if (modalStepState.step === LeadModalStep.APPLICATION_FORM) {
            gtmStepApplicationClose();
        }

        if (modalStepState.step === LeadModalStep.MULTILEAD_SUMMARY) {
            multiLeadAdditionalOfferAnalytics.gtm.multiLeadAdditionalOfferEvent(AdditionalOfferCategoryGTMEventAction.CLOSE);
        }

        const forceClose = forceCloseParam || restProps.forceClose || false;
        dispatchModalStepState({type: ModalStepActionTypes.CLOSE, payload: {forceClose}});
    };

    const onAfterRecommendedSuccess = (originalApplicationResponse: IApplication | null) => {
        dispatchModalStepState({
            type: ModalStepActionTypes.NEXT_STEP,
            payload: {originalApplicationResponse}
        });
    };

    const onShowArchitectOfferButtonClick = (architectOfferDetails: IMarketplaceArchitectOfferSummary, architectRegion: IRegionAncestor | null) => {
        dispatchModalStepState({
            type: ModalStepActionTypes.NEXT_STEP,
            payload: {architectOfferDetails, architectRegion}
        });
    };

    const onShowPmOfferButtonClick = () => {
        dispatchModalStepState({
            type: ModalStepActionTypes.NEXT_STEP,
            payload: {
                showPmOffer: true
            }
        });
    };

    const onArchitectOfferApplied = (architectOfferDetails: IMarketplaceArchitectOfferSummary, architectRegion: IRegionAncestor | null) => {
        dispatchModalStepState({
            type: ModalStepActionTypes.NEXT_STEP,
            payload: {architectOfferDetails, architectRegion}
        });
    };

    const dispatchNextStepWithoutPayload = () => {
        dispatchModalStepState({
            type: ModalStepActionTypes.NEXT_STEP
        });
    };

    const setMultiLeadStep = (payload: IMultiLeadStepPayload) => {
        dispatchModalStepState({
            type: ModalStepActionTypes.SET_MULTILEAD_STEP,
            payload
        });
    };

    const setNotificationConsentStep = (payload: INotificationConsentStepPayload) => {
        dispatchModalStepState({
            type: ModalStepActionTypes.SET_NOTIFICATION_CONSENT_STEP,
            payload
        });
    };

    //  TODO: VendorMultilead is temporarily turned off, do not remove this code
    // const setSingleLeadSummaryStep = (originalApplicationResponse: IApplication | null) => {
    //     dispatchModalStepState({
    //         type: ModalStepActionTypes.SINGLE_LEAD_SUMMARY_STEP,
    //         payload: {
    //             originalApplicationResponse
    //         }
    //     });
    // };

    const setAfterMultiLeadStep = (payload: IMultileadSummaryStepState) => {
        dispatchModalStepState({
            type: ModalStepActionTypes.NEXT_STEP,
            payload
        });
    };

    const setModalClose = () => {
        dispatchModalStepState({
            type: ModalStepActionTypes.SET_CLOSE
        });
    };

    const appendPreMultiLeadApplications = (multiLeadSummaryItems: IMultileadSummaryStepState["multiLeadSummaryItems"]) => {
        dispatchModalStepState({
            type: ModalStepActionTypes.APPEND_MULTI_LEAD_APPLICATIONS,
            payload: {multiLeadSummaryItems}
        });
    };

    const setMultiLeadSummaryStep = (payload?: ISetMultiLeadSummaryStepPayload) => {
        dispatchModalStepState({
            type: ModalStepActionTypes.SET_MULTILEAD_SUMMARY_STEP,
            payload
        });
    };

    const setUserBudgetMultiLeadStep = (recommendedProperties: IRecommendedProperty[]) => {
        dispatchModalStepState({
            type: ModalStepActionTypes.SET_USER_BUDGET_MULTI_LEAD_STEP,
            payload: {
                originalApplicationResponse: null,
                recommendedOffers: recommendedProperties,
                shouldCloseOnOverlayClick: false,
                recommendationsWithPriceFilter: true
            }
        });
    };

    const setUserBudgetDefaultMultiLeadStep = async () => {
        const recommendedOffers = await fetchSimpleMultiLeadRecommendations({
            dispatch,
            offer: leadData?.offer?.detail,
            property: leadData?.property?.detail,
            sentApplication: modalStepState.originalApplicationResponse
        });

        setMultiLeadStep({
            originalApplicationResponse: null,
            recommendedOffers: recommendedOffers,
            shouldCloseOnOverlayClick: false,
            recommendationsWithPriceFilter: true,
            multiLeadProperty: modalStepState.multiLeadProperty
        });
    };

    const setUserBudgetStep = () => {
        dispatchModalStepState({
            type: ModalStepActionTypes.SET_USER_BUDGET_STEP
        });
    };

    const setUserBudgetNoRecommendationsStep = () => {
        dispatchModalStepState({
            type: ModalStepActionTypes.SET_USER_BUDGET_NO_RECOMMENDATIONS_STEP
        });
    };

    const setGoodbyInfoStep = () => {
        dispatchModalStepState({
            type: ModalStepActionTypes.SET_GOODBYE_INFO_STEP,
            payload: {
                isClosing: true
            }
        });
    };

    const handleCloseMultiLead = (payload?: ISetMultiLeadSummaryStepPayload) => {
        report.modalClose(recommendationType, currentViewType);

        const hasAnyLeadBesideOriginal = Boolean(payload && payload.multiLeadSummaryItems.length > 1);
        const hasOnlyOriginalSuccesfullLead = Boolean(modalStepState.originalApplicationResponse);
        const shouldSetSummaryStep = hasAnyLeadBesideOriginal || hasOnlyOriginalSuccesfullLead;

        if (shouldSetSummaryStep) {
            setMultiLeadSummaryStep(payload);
            return;
        }

        dispatch({type: multiLeadApplicationsActionsTypes.reset, id: parametrizedStoreHash});
        setModalClose();
    };

    const handleCloseUserBudget = () => {
        const hasAnyLeadBesideOriginal = modalStepState.multiLeadSummaryItems.length > 1;
        const hasOnlyOriginalSuccesfullLead = Boolean(modalStepState.originalApplicationResponse);

        const shouldSetSummaryStep = hasAnyLeadBesideOriginal || hasOnlyOriginalSuccesfullLead;

        if (shouldSetSummaryStep) {
            setMultiLeadSummaryStep({
                multiLeadSummaryItems: modalStepState.multiLeadSummaryItems,
                multiLeadProperty: modalStepState.multiLeadProperty
            });
            return;
        }

        setGoodbyInfoStep();
    };

    const onSingleLeadSuccess = async (
        sentApplication: IApplication | null,
        offer?: IApplicationOffer,
        property?: IApplicationProperty,
        recommendedFilters?: Record<string, string>,
        onBeforeNextStep?: () => Promise<void>
    ) => {
        dispatchModalStepState({
            type: ModalStepActionTypes.UPDATE_SINGLE_LEAD_RESPONSE_DATA,
            payload: {
                originalApplicationResponse: sentApplication
            }
        });

        let recommendedList: IRecommendedProperty[] | IRecommendedOffer[] | null = null;
        const multiLeadProperty: IOriginalApplicationProperty | null = property;

        // const isAbroadLead = isAbroad(leadData?.offer?.detail?.region.country); // TODO: VendorMultilead is temporarily turned off, replace logic when turning on

        // if (isAbroadLead) { //TODO: VendorMultilead is temporarily turned off, replace logic when turning on
        recommendedList = await fetchSimpleMultiLeadRecommendations({
            dispatch,
            offer,
            property,
            sentApplication,
            recommendedFilters
        });

        // TODO: VendorMultilead is temporarily turned off, do not remove this code
        // if (!isAbroadLead) {
        //     const vendorRecommendations = await fetchVendorRecommendations(
        //         {
        //             offerId: offer?.id,
        //             originalApplicationProperty: property,
        //             excludedOfferIds,
        //             radius: VENDOR_MULTI_LEAD_DISTANCE_IN_KM
        //         },
        //         dispatch
        //     );
        //
        //     recommendedList = vendorRecommendations.results;
        //     multiLeadProperty = vendorRecommendations.leadProperty;
        // }

        if ((!recommendedList || recommendedList?.length === 0) && onBeforeNextStep) {
            await onBeforeNextStep();
        }

        if (typeof restProps.onSuccessfulSubmit === "function") {
            restProps.onSuccessfulSubmit(sentApplication as IApplication);
        }

        if (!recommendedList || recommendedList.length === 0) {
            const recommendationsType = property ? MultiLeadRecommendationType.PROPERTY : MultiLeadRecommendationType.OFFER;

            hitMultileadRecommendationShown({
                //  recommendationType: isAbroadLead ? recommendationsType : MultiLeadRecommendationType.VENDOR, // TODO: VendorMultilead is temporarily turned off, replace logic when turning on
                recommendationType,
                recommendationId: null,
                viewType: currentViewType,
                recommendationSource: recommendationSource,
                multileadVersion:
                    recommendationsType === MultiLeadRecommendationType.PROPERTY ? MultileadVersion.RP_LIST_PROPERTY : MultileadVersion.RP_LIST_OFFER
            });
        }

        const originalApplication = sentApplication ? sentApplication : null;
        const isWithOriginalApplicationResponse = Boolean(originalApplication);
        const recommendationsWithPriceFilter = recommendedFilters ? "max_price" in recommendedFilters : false;
        const isWithRecommendations = recommendedList && recommendedList.length > 0;
        const isLeadWithPriceConfirmationCheck = isLeadWithPriceConfirmation(leadData);

        // TODO: We probably don't need separate field for multileadProperty, it's in application/create response, so in redux
        if (isWithOriginalApplicationResponse && isWithRecommendations) {
            setMultiLeadStep({
                originalApplicationResponse: originalApplication,
                recommendedOffers: recommendedList,
                shouldCloseOnOverlayClick: false,
                multiLeadProperty,
                recommendationsWithPriceFilter
            });
            return;
        }

        /**
         * TODO Should we actually have this case?
         *  We want to show multilead without main lead - in "price ask" scenario.
         *  But - do we actually assume we won't create lead after price confirmation? Confirm logic with business
         *  In general, we shouldn't have same logic for normal submit and price scenarios.
         *  For example - "!isWithOriginalApplicationResponse && isLeadWithPriceConfirmationCheck" actually means - "We have a price ask scenario"
         */
        if (!isWithOriginalApplicationResponse && isWithRecommendations && isLeadWithPriceConfirmationCheck) {
            setMultiLeadStep({
                originalApplicationResponse: null,
                recommendedOffers: recommendedList,
                shouldCloseOnOverlayClick: false,
                recommendationsWithPriceFilter,
                multiLeadProperty
            });
            return;
        }

        const isMktOptinSelected = !sentApplication?.new_property_notification_consent;
        if (isWithOriginalApplicationResponse && !isMktOptinSelected) {
            setNotificationConsentStep({
                originalApplicationResponse: originalApplication
            });
            return;
        }

        if (isWithOriginalApplicationResponse) {
            /**
             * Lead property/offer is not needed in summary items, as it's already in MultiLeadSentSummary
             * For the others - it's the case where we don't have recommendations nor MultiLead,
             * Pre-multilead items are already appended to MultiLeadSentSummary via appendPreMultiLeadApplications
             */
            setMultiLeadSummaryStep({
                multiLeadSummaryItems: [],
                multiLeadProperty
            });
            return;
        }

        setModalClose();
        return;
    };

    // Render
    const splitModal = modalStepState.step === LeadModalStep.APPLICATION_FORM || modalStepState.step === LeadModalStep.GOODBYE_INFO;

    const renderContent = () => {
        // render cases
        switch (modalStepState.step) {
            case LeadModalStep.APPLICATION_FORM: {
                return (
                    <>
                        {(!initialLeadFormValues || !leadData) && <Loader size="lg" fullWidth />}
                        {initialLeadFormValues && leadData && (
                            <Application
                                {...idProps}
                                appendPreMultileadApplications={appendPreMultiLeadApplications}
                                initialLeadFormValues={initialLeadFormValues}
                                leadData={leadData}
                                onSuccessfulSubmit={onSingleLeadSuccess}
                                handleChangeUserBudget={setUserBudgetStep}
                                handlePropertyPriceModalClose={handleCloseUserBudget}
                                hideMessageSection={restProps.hideMessageSection}
                                storeHash={parametrizedStoreHash}
                                source={restProps.source}
                                sourceSection={restProps.sourceSection}
                                salesOfficeId={restProps.salesOfficeId}
                                promotion={restProps.promotion}
                                promotionDateString={restProps.promotionDateString}
                                isModal={restProps.isModal}
                                preventStorageOfferSearchLoad={restProps.preventStorageOfferSearchLoad}
                                hideRooms={restProps.hideRooms}
                                alwaysShowFields
                                hideRodo={window.innerWidth > 1024}
                                hideSocialLoginButtons
                                hideSubmitButton={false}
                                hideHeaderDivider={false}
                                affiliation={restProps.affiliation}
                                closeModal={onModalClose}
                                hideQuestions={restProps.hideQuestions ?? leadData.offer?.detail?.region.country !== Country.POLAND}
                                submitOnMount={restProps.submitOnMount}
                                applicationVariant={applicationVariant}
                                creditPayload={restProps.creditPayload}
                                customFormText={customFormText}
                            />
                        )}
                    </>
                );
            }
            case LeadModalStep.USER_BUDGET: {
                return (
                    <>
                        {(!initialLeadFormValues || !leadData) && <Loader size="lg" fullWidth />}
                        {initialLeadFormValues && leadData && (
                            <ApplicationPropertyPriceUserBudgetModal
                                leadData={leadData}
                                setUserBudgetMultiLeadStep={setUserBudgetMultiLeadStep}
                                setDefaultMultiLeadStep={setUserBudgetDefaultMultiLeadStep}
                                setUserBudgetNoRecommendationsStep={setUserBudgetNoRecommendationsStep}
                                recommendationSource={recommendationSource}
                                onSuccessfulSubmit={() => {
                                    if (typeof restProps.onSuccessfulSubmit === "function") {
                                        restProps.onSuccessfulSubmit(null);
                                    }
                                }}
                                onClose={() => {
                                    handleCloseUserBudget();
                                }}
                            />
                        )}
                    </>
                );
            }
            case LeadModalStep.USER_BUDGET_NO_RECOMMENDATIONS: {
                return (
                    <ApplicationPropertyPriceUserBudgetNoRecommendationsModal
                        handleBudgetChange={setUserBudgetStep}
                        handleShowOffers={setUserBudgetDefaultMultiLeadStep}
                        handleClose={handleCloseUserBudget}
                    />
                );
            }
            case LeadModalStep.USER_BUDGET_MULTI_LEAD: {
                const sentLeadFormValues = getSentLeadFormValuesFromStorage();
                const originalApplicationFormValues = sentLeadFormValues
                    ? {...sentLeadFormValues, phone: getPhoneDataFromString(sentLeadFormValues.phone)}
                    : {...initialEmptyApplicationValues, rooms: [roomCount]};

                return (
                    <>
                        {(!initialLeadFormValues || !leadData) && <Loader size="lg" fullWidth />}
                        {initialLeadFormValues && leadData && (
                            <SameOfferRecommendedPropertiesModal
                                leadData={leadData}
                                storeHash={parametrizedStoreHash}
                                sourceSection={ApplicationSourceSection.MULTILEAD}
                                customSourceSection={restProps.customSourceSection}
                                originalApplicationFormValues={originalApplicationFormValues}
                                recommendationsWithPriceFilter={modalStepState.recommendationsWithPriceFilter}
                                sameOfferRecommendedProperties={(modalStepState.recommendedOffers as IRecommendedProperty[]) || null}
                                recommendationType={recommendationType}
                                handleSameOfferRecommendedPropertiesAfterStep={setMultiLeadSummaryStep}
                                handleModalClose={handleCloseUserBudget}
                            />
                        )}
                    </>
                );
            }
            case LeadModalStep.NOTIFICATION_CONSENT: {
                return (
                    <NotificationConsentConfirmation
                        {...restProps}
                        storeHash={parametrizedStoreHash}
                        result={modalStepState.originalApplicationResponse}
                        onSuccessfulSubmit={onSingleLeadSuccess}
                        onModalClose={onModalClose}
                    />
                );
            }
            case LeadModalStep.SUCCESS_INFO: {
                return (
                    <FormSuccessInfoContainer
                        isModal
                        modalStepState={modalStepState}
                        onClick={() =>
                            dispatchModalStepState({
                                type: ModalStepActionTypes.NEXT_STEP
                            })
                        }
                        shouldRenderRecommendations
                        onModalClose={onModalClose}
                    />
                );
            }
            case LeadModalStep.GOODBYE_INFO: {
                return (
                    <ApplicationGoodbyeContentModal
                        storeHash={parametrizedStoreHash}
                        source={restProps.source}
                        sourceSection={restProps.sourceSection}
                        // TODO: Should  we remove this and code related to it, or make it work?
                        // traders={}
                        // salesOffice={}
                        className={restProps.className}
                        closeModal={onModalClose}
                    />
                );
            }
            case LeadModalStep.MULTILEAD: {
                const sentLeadFormValues = getSentLeadFormValuesFromStorage();

                if (!sentLeadFormValues && !preventClosingWhenNoData) {
                    // application data should be filled at this point. But just in case:
                    onModalClose();

                    return null;
                }

                const originalApplicationFormValues = sentLeadFormValues
                    ? {...sentLeadFormValues, phone: getPhoneDataFromString(sentLeadFormValues.phone)}
                    : {...initialEmptyApplicationValues, rooms: [roomCount]};

                // TODO: VendorMultilead is temporarily turned off, do not remove this code
                // const isAbroadLead = isAbroad(leadData?.offer?.detail?.region.country);
                //
                // if (!isAbroadLead && leadData?.offer) {
                //     return (
                //         <VendorMultilead
                //             /**
                //              * Currently, modalStepReducer checks for recommendations (payload.recommendedOffers.length > 0).
                //              * Additionally, for vendorMultileadRecommendations, we only fetch for properties (fetchVendorRecommendations).
                //              * Therefore, we can safely cast vendorMultileadRecommendations to IRecommendedProperty[]
                //              */
                //             storeHash={parametrizedStoreHash}
                //             recommendations={modalStepState.recommendedOffers as IRecommendedProperty[]}
                //             originalApplicationFormValues={originalApplicationFormValues}
                //             originalApplicationResponse={modalStepState.originalApplicationResponse}
                //             multiLeadProperty={modalStepState.multiLeadProperty}
                //             handleSetMultiLeadSummaryStep={setMultileadSummaryStep}
                //             handleSetSingleLeadSummaryStep={setSingleLeadSummaryStep}
                //             handleModalClose={onModalClose}
                //         />
                //     );
                // }

                return (
                    <MultiLead
                        {...idProps}
                        originalApplicationResponse={modalStepState.originalApplicationResponse}
                        originalApplicationFormValues={originalApplicationFormValues}
                        storeHash={parametrizedStoreHash}
                        sourceSection={ApplicationSourceSection.MULTILEAD}
                        recommendationType={recommendationType}
                        recommendationsWithPriceFilter={modalStepState.recommendationsWithPriceFilter}
                        customSourceSection={restProps.customSourceSection}
                        handleModalClose={handleCloseMultiLead}
                        handleSetMultiLeadSummaryStep={setAfterMultiLeadStep}
                    />
                );
            }
            case LeadModalStep.MULTILEAD_SUMMARY: {
                const sentLeadFormValues = getSentLeadFormValuesFromStorage();
                const originalApplicationFormValues = sentLeadFormValues
                    ? {...sentLeadFormValues, phone: getPhoneDataFromString(sentLeadFormValues.phone)}
                    : {...initialEmptyApplicationValues, rooms: [roomCount]};

                const isAbroadLead = isAbroad(leadData?.offer?.detail?.region.country);
                const multiLeadSummaryVariantToShow = isAbroadLead ? 0 : multiLeadSummaryVariant;

                switch (multiLeadSummaryVariantToShow) {
                    case 1:
                        return (
                            <MultileadSentSummarySimplifiedWithComparison
                                storeHash={parametrizedStoreHash}
                                leadData={leadData}
                                leadModalStepState={modalStepState}
                                handleSetNextStep={onAfterRecommendedSuccess}
                                handleCloseModal={onModalClose}
                                handleSetArchitectInteriorDesignOfferStep={onShowArchitectOfferButtonClick}
                                handleSetPmOfferStep={onShowPmOfferButtonClick}
                            />
                        );
                    case 2:
                        return (
                            <MultileadSentSummaryWithCollapsedOffers
                                originalApplicationFormValues={originalApplicationFormValues}
                                storeHash={parametrizedStoreHash}
                                leadData={leadData}
                                leadModalStepState={modalStepState}
                                handleSetNextStep={onAfterRecommendedSuccess}
                                handleCloseModal={onModalClose}
                                handleSetArchitectInteriorDesignOfferStep={onShowArchitectOfferButtonClick}
                                handleSetPmOfferStep={onShowPmOfferButtonClick}
                            />
                        );
                    default:
                        return (
                            <MultiLeadSentSummary
                                storeHash={parametrizedStoreHash}
                                leadData={leadData}
                                leadModalStepState={modalStepState}
                                handleSetNextStep={onAfterRecommendedSuccess}
                                handleCloseModal={onModalClose}
                                handleSetArchitectInteriorDesignOfferStep={onShowArchitectOfferButtonClick}
                                handleSetPmOfferStep={onShowPmOfferButtonClick}
                            />
                        );
                }
            }
            case LeadModalStep.SINGLE_LEAD_SUMMARY: {
                return <AppliedProperties storeHash={parametrizedStoreHash} onShowArchitectOfferButtonClick={onShowArchitectOfferButtonClick} />;
            }
            case LeadModalStep.ARCHITECT_OFFER: {
                return (
                    <ArchitectOfferSummaryModalContent
                        storeHash={parametrizedStoreHash}
                        architectOfferDetails={modalStepState.architectOfferDetails}
                        architectRegion={modalStepState.architectRegion}
                        onArchitectOfferApplied={onArchitectOfferApplied}
                        userData={{
                            name: modalStepState.originalApplicationResponse?.name,
                            email: modalStepState.originalApplicationResponse?.email,
                            phone: modalStepState.originalApplicationResponse?.phone
                        }}
                    />
                );
            }
            case LeadModalStep.PM_OFFER: {
                return (
                    <PMPromoModalLayout
                        originalApplicationResponse={modalStepState.originalApplicationResponse}
                        storeHash={parametrizedStoreHash}
                        leadData={leadData}
                        onSuccessfulSubmit={dispatchNextStepWithoutPayload}
                    />
                );
            }
            case LeadModalStep.PM_APPLIED_OFFER: {
                return <AppliedPMOffer onCloseButtonClick={dispatchNextStepWithoutPayload} />;
            }
            case LeadModalStep.APPLIED_ARCHITECT_OFFER: {
                return (
                    <AppliedArchitectInteriorDesign
                        architectOfferDetails={modalStepState.architectOfferDetails}
                        onCloseButtonClick={dispatchNextStepWithoutPayload}
                    />
                );
            }
            case LeadModalStep.ARCHITECT_MULTILEAD: {
                return (
                    <ArchitectMultileadModalContent
                        region={modalStepState.architectRegion?.name}
                        userData={{
                            name: modalStepState.originalApplicationResponse?.name || initialLeadFormValues?.name || "",
                            email: modalStepState.originalApplicationResponse?.email || initialLeadFormValues?.email || "",
                            phone:
                                modalStepState.originalApplicationResponse?.phone ||
                                `${initialLeadFormValues?.phone.code} ${initialLeadFormValues?.phone.number}` ||
                                "",
                            area: ""
                        }}
                        mainAppliedArchitectOffer={modalStepState.architectOfferDetails}
                    />
                );
            }
            default:
                return <span />;
        }
    };

    const shouldCloseOnOverlayClick =
        restProps.submitOnMount && modalStepState.step === LeadModalStep.APPLICATION_FORM ? false : modalStepState.shouldCloseOnOverlayClick;

    return (
        <FormModal
            isOpen={isOpened}
            onModalClose={() => onModalClose(false)}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
            css={getModalStyle(modalStepState.step)}
            showCloseButton={
                ![
                    LeadModalStep.MULTILEAD,
                    LeadModalStep.APPLICATION_FORM,
                    LeadModalStep.GOODBYE_INFO,
                    LeadModalStep.USER_BUDGET,
                    LeadModalStep.USER_BUDGET_MULTI_LEAD,
                    LeadModalStep.USER_BUDGET_NO_RECOMMENDATIONS
                ].includes(modalStepState.step)
            }
        >
            {modalStepState.step === LeadModalStep.APPLICATION_FORM ? (
                <div css={newApplicationWrapStyle}>
                    <div css={newApplicationHolderStyle}>{renderContent()}</div>
                </div>
            ) : (
                <ApplicationWrapper>
                    <ApplicationHolder wide={!splitModal}>{renderContent()}</ApplicationHolder>
                </ApplicationWrapper>
            )}
        </FormModal>
    );
};

// Styles
const getModalStyle = (step: LeadModalStep) => (theme: Theme) => {
    switch (step) {
        case LeadModalStep.NOTIFICATION_CONSENT:
        case LeadModalStep.SUCCESS_INFO:
            return css`
                @media (min-width: ${theme.breakpoints.md}) {
                    width: 896px;
                    height: auto;
                }
            `;
        case LeadModalStep.GOODBYE_INFO:
            return css`
                @media (min-width: ${theme.breakpoints.md}) {
                    width: 896px;
                    height: 644px;
                }
            `;
        case LeadModalStep.MULTILEAD:
        case LeadModalStep.MULTILEAD_SUMMARY:
            return css`
                @media (min-width: ${theme.breakpoints.md}) {
                    width: 1000px;
                    max-height: 700px;
                }
            `;

        case LeadModalStep.SINGLE_LEAD_SUMMARY:
            return css`
                @media (min-width: ${theme.breakpoints.md}) {
                    width: 936px;
                    height: 644px;
                }
            `;
        case LeadModalStep.ARCHITECT_OFFER:
            return css`
                @media (min-width: ${theme.breakpoints.md}) {
                    width: 950px;
                    height: auto;
                }
            `;
        case LeadModalStep.APPLIED_ARCHITECT_OFFER:
            return css`
                @media (min-width: ${theme.breakpoints.md}) {
                    width: 860px;
                    height: auto;
                }
            `;
        case LeadModalStep.PM_OFFER:
            return css`
                @media (min-width: ${theme.breakpoints.md}) {
                    width: 950px;
                    height: 520px;
                }
            `;
        case LeadModalStep.PM_APPLIED_OFFER:
            return css`
                @media (min-width: ${theme.breakpoints.md}) {
                    width: 850px;
                    height: auto;
                }
            `;
        case LeadModalStep.ARCHITECT_MULTILEAD:
            return css`
                width: 100%;

                @media (min-width: ${theme.breakpoints.md}) {
                    max-width: 745px;
                    height: auto;
                }
            `;
        case LeadModalStep.USER_BUDGET_MULTI_LEAD:
            return css`
                @media (min-width: ${theme.breakpoints.md}) {
                    width: 810px;
                }
            `;
    }
};

const FormModal = styled(ApplicationStyledModal)`
    ${fadeInAnimation("0.1s")};
    ${p(0)};

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        width: unset;
        height: unset;
    }
`;

const newApplicationWrapStyle = css`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    overflow: hidden;
    height: 100%;
`;

const newApplicationHolderStyle = css`
    width: 100%;
    min-width: 28rem;
    overflow-y: auto;
    position: relative;
`;

const ApplicationWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    overflow: hidden;
    height: 100%;
`;

interface IApplicationHolderProps {
    wide?: boolean;
}

const ApplicationHolder = styled.div<IApplicationHolderProps>`
    width: 100%;
    height: 100%;
    min-width: 28rem;
    overflow-y: auto;
    position: relative;
`;

function getStoreHash(storeHash: string, ids: {vendorId: number; offerId?: number; propertyId?: number}) {
    const offerText = ids.offerId ? `o${ids.offerId}` : "";
    const propertyText = ids.propertyId ? `p${ids.propertyId}` : "";

    return `v${ids.vendorId}${offerText}${propertyText}`;
}
